import * as React from 'react';
import {Redirect} from "react-router-dom";
import cl from "clsx";
import styled from "@emotion/styled";

//ICONS
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

//HOOKS
import {forwardRef, useState} from "react";
import {useCookies} from "react-cookie";

//COMPONENTS
import Header from "./Header";
import TextField from '@mui/material/TextField';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    Slide,
    withStyles
} from "@material-ui/core";
import {Alert, IconButton, InputAdornment} from "@mui/material";

import {PASSWORD} from '../const'
import i18n from "../i18n";
import {withNamespaces} from "react-i18next";

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#52bbab',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#52bbab',
    },
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: '#52bbab',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#52bbab',
        },
    },
});

export const CssButton = withStyles({
    root: {
        background: '#f5c61a',
        borderRadius: '3px',
        color: 'white',
        fontSize: "20px",
        border: '2px solid white',
        padding: '.5rem 1rem',
        whiteSpace: 'nowrap',
        boxShadow: '0 4px 8px 0',
        '&:hover': {
            backgroundColor: '#f7d451',
        },
        '& .MuiFormControl-marginNormal': {
            margin: '0px'
        }
    },
    label: {
        textTransform: 'none',
    },
})(Button)

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function Login({t}) {

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }

    const handleChangeTranslate = (lang) => {
        changeLanguage(lang)
        setShowModal(false)
    };

    const [showModal, setShowModal] = useState(true)
    const [cookies, setCookie] = useCookies();

    const handleOnClick = () => {
        clickLogin()
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const [values, setValues] = React.useState({
        email: '',
        password: '',
        showPassword: false,
    });

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    const checkLogin = () => {
        if (values.password === PASSWORD && !loading) {
            setError(false)
            setCookie('password', values.password)
        } else {
            setError(true)
        }
    }

    async function clickLogin() {
        setError(false)
        if (values.email && values.password) {
            setLoading(true)
            setTimeout(async () => {
                setLoading(false)
                checkLogin()
            }, 1000)
        }
    }

    const handleChange = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const handleSubmit = (event) => {
        event.preventDefault();
    }

    if (cookies?.password === PASSWORD) {
        return <Redirect to={`/`}/>
    }

    return (
        <div style={{position:"relative"}}>
            <Header/>
            {
                showModal &&
                    <Dialog
                        TransitionComponent={Transition}
                        keepMounted
                        open={handleOpenModal}
                        aria-describedby="alert-dialog-slide-translate"
                    >
                        <DialogContent>
                           <div>
                               <CssButton onClick={() => {handleChangeTranslate('de')}}>DE</CssButton>
                               <CssButton onClick={() => {handleChangeTranslate('en')}}>EN</CssButton>
                           </div>
                        </DialogContent>
                    </Dialog>
            }
            <div className={cl('login')}>
            </div>
            <div className={cl('login_modal__wrapper')}>
                <div className={cl('login_modal')}>
                    <h1 className={cl('login_title')}>Login</h1>
                    <p className={cl('login_subtitle')}>
                        {t('login_subtitle')}
                    </p>
                    {error &&
                    <Alert style={{background: '#f39100', marginTop: '20px'}} variant="filled" severity="warning">
                        {t('passwordError')}
                    </Alert>
                    }
                    <form onSubmit={handleSubmit} action="">
                        <div className={cl('wrapper_input')}>
                            <CssTextField value={values.email} onChange={handleChange('email')} required fullWidth
                                          type='email' id="standard-basic" label={t('email')}
                                          variant="standard"/>
                        </div>
                        <div className={cl('wrapper_input')}>
                            <CssTextField
                                required
                                fullWidth
                                id="outlined-adornment-password"
                                type={values.showPassword ? 'text' : 'password'}
                                value={values.password}
                                onChange={handleChange('password')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {values.showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {values.showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                                label={t('password')}
                                variant="standard"/>
                        </div>
                        <div className={cl('wrapper_button')}>
                            <CssButton onClick={handleOnClick} type='submit'
                                       fullWidth variant='outlined'>
                            <span className={cl('login_button__text')}>
                               {loading ?
                                   <Box sx={{display: 'flex'}}>
                                       <CircularProgress size={20} style={{color: 'white'}}/>
                                   </Box>
                                   : 'LOGIN'}
                            </span>
                            </CssButton>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default withNamespaces()(Login);
