import {useEffect, useState} from "react";
import {LANG} from "../const";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { withNamespaces } from 'react-i18next';
import i18n from "i18next";
function Languages() {

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }

    const [lang, setLang] = useState(i18n.language);

    useEffect(() => {

    },[i18n.language])

    const handleChange = (event) => {
        setLang(event.target.value);
        changeLanguage(event.target.value)
    };

    return (
        <div >
            <Select
                MenuProps={{autoFocus: false, disableScrollLock:true}}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={i18n.language}
                onChange={handleChange}
            >
                {
                    LANG.map((el, index) => {
                        return <MenuItem key={el.lang} value={el.lang}>{el.lang.toUpperCase()}</MenuItem>
                    })
                }
            </Select>
        </div>
    );
}

export default withNamespaces()(Languages);
