import cl from "clsx";
import {Route} from "react-router-dom";

//COMPONENTS
import Footer from "./components/Footer";
import Login from "./components/Login";
import CoursePage from "./components/CoursePage";
import * as React from "react";
import Header from "./components/Header";

function App() {

    return (
            <div className={cl('app')}>
                <Route path={['/']} exact component={Header}/>
                <Route path={['/login', '/login']} exact component={Login}/>
                <Route path={[ '/']} exact component={CoursePage}/>
                <Footer/>
            </div>
    );
}

export default App;
