import cl from "clsx";
import {withNamespaces} from "react-i18next";

function Footer({t}) {
    return (
        <div className={cl('footer')}>
            <p className={cl('footer__title')}>{t('footer')} – powered by
                <a className={cl('footer__title__link')} href={'https://education.omr.com'}>
                    OMR Education
                </a>
            </p>
        </div>
    );
}

export default withNamespaces()(Footer);
