import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#333333',
        },
        secondary: {
            main: '#333333',
        },
    },
});

export default theme