import cl from "clsx";

//IMAGES
import logo from '../images/OMR_Academy_Logo_black.svg'
import pokal from '../images/pokal.png'
import glocke from '../images/glocke.png'

//COMPONENTS
import MenuIcon from '@mui/icons-material/Menu';
import {IconButton} from '@mui/material';
import {Dialog, DialogContent, DialogContentText, Slide} from "@material-ui/core";

//HOOKS
import {useHistory} from "react-router-dom";
import {forwardRef, useState} from "react";
import Languages from "./Languages";
import { withNamespaces } from 'react-i18next';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Header({t}) {


    const route = useHistory()
    const coursePage = route.location.pathname !== '/login'

    const [openCup, setOpenCup] = useState(false);
    const [openBell, setOpenBell] = useState(false);

    const handleOpenCup= () => {
        setOpenCup(true);
    };
    const handleCloseCup = () => {
        setOpenCup(false);
    };

    const handleOpenBell= () => {
        setOpenBell(true);
    };
    const handleCloseBell = () => {
        setOpenBell(false);
    };

    return (

        <div className={cl('header')}>
            <div style={{display: 'flex'}}>
                {coursePage &&
                <div className={cl('header_menu')}>
                    <IconButton>
                        <MenuIcon/>
                    </IconButton>
                </div>}
                <img className={cl('logo')} src={logo} alt="logo"/>
            </div>
            {!coursePage && <Languages/>}
            {coursePage &&
            <div style={{display: 'flex'}}>
                {
                    openCup &&
                    <div>
                        <Dialog
                            open={openCup}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleCloseCup}
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description-area">
                                    {t('modalIconCup')}
                                </DialogContentText>
                            </DialogContent>
                        </Dialog>
                    </div>
                }
                {
                    openBell &&
                    <div>
                        <Dialog
                            open={openBell}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleCloseBell}
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description-area">
                                    {t('modalIconBell')}
                                </DialogContentText>
                            </DialogContent>
                        </Dialog>
                    </div>
                }
                <Languages/>
                <IconButton onClick={handleOpenCup}>
                    <img className={cl('header_menu_icon')} src={pokal} alt="pokal"/>
                </IconButton>
                <IconButton onClick={handleOpenBell}>
                    <img className={cl('header_menu_icon')} src={glocke} alt="glocke"/>
                </IconButton>
            </div>
            }
        </div>
    );
}

export default withNamespaces()(Header);
