import cl from "clsx";

import {Redirect} from 'react-router-dom'

//COMPONENTS
import {Button, Dialog, DialogContent, DialogContentText, Slide, withStyles} from "@material-ui/core";
import CourseCard from "./CourseCard";
import ModalVideo from 'react-modal-video'

//HOOKS
import {forwardRef, useState} from "react";
import { useCookies } from 'react-cookie';

//IMAGES
import startsite from '../images/starseite.png'
import startsite_header_de from '../images/startseiten_header_de.png'
import startsite_header_en from '../images/startseiten_header_en.png'
import startsite_footer from '../images/startseite_footer.png'
import lerninhalte_de from '../images/lerninhalte_de.png'
import lerninhalte_en from '../images/lerninhalte_en.png'
import kursabschluss_de from '../images/kursabschluss_de.png'
import kursabschluss_en from '../images/kursabschluss_en.png'
import praxisanwendung_de from '../images/praxisanwendung_de.png'
import praxisanwendung_en from '../images/praxisanwendung_en.png'
import livesessions_de from '../images/livesessions_de.png'
import livesessions_en from '../images/livesessions_en.png'
import willkommen from '../images/willkommen.png'
import slack from '../images/slack.png'

import { PASSWORD} from '../const'
import * as React from "react";
import { withNamespaces } from 'react-i18next';

const listCourse = [
    {
        img: {
            de:lerninhalte_de,
            en:lerninhalte_en
        },
        title: {
            de:'Lerninhalte',
            en:'Content'
        },
        count: {
            de:'11 Kurse | 25h 30m',
            en:'11 Courses | 25h 30m'
        },
        popup:""
    },
    {
        img: {
            de:praxisanwendung_de,
            en:praxisanwendung_en
        },
        title: {
            de:'Praxisanwendung',
            en:'Exercises'
        },
        count:  {
            de:'1 Kurse',
            en:'1 Course'
        },
        popup:{
            de:"Passend zu deinem gewählten Kurs kannst du hier die Anleitung für deine Praxisanwendung ansehen. Wir \n" +
                "stellen dir Fragen und Aufgaben zum besprochenen Kurs. Du kannst so das Gelernte direkt anwenden und in deinen Alltag überführen.",
            en:"In accordance with your chosen course content, you can view your practice assignment here. We will ask you questions and tasks related to the discussed course. This way you can directly apply what you have learned and transfer it to your everyday life.\n",
        }

    },
    {
        img: {
            de:livesessions_de,
            en:livesessions_en
        },
        title: {
            de:'Live Sessions',
            en:'Live Sessions'
        },
        count: {
            de:'4 Kurse',
            en:'4 Courses'
        },
        popup:{
            de:'Dein Kurs wird von führenden Expert*innen auf ihrem Gebiet begleitet. In den Live Sessions stehen sie dir für alle deine Fragen zur Verfügung. Von hier aus kannst du direkt daran teilnehmen. Die Sessions sind komplett digital. Und wenn du keine Zeit hast, kein Problem! Direkt am Folgetag steht dir an dieser Stelle die Aufzeichnung zur Verfügung. Deine Fragen an die Expert*innen kannst du darüber hinaus auch auf Slack stellen.',
            en:'Your course will be led by leading experts in their field. During the live sessions they will answer all your questions. The sessions are completely digital. And if you miss one of these sessions? No problem! The recording will be available here directly on the following day.'
        }
    },
    {
        img: {
            de:kursabschluss_de,
            en:kursabschluss_en
        },
        title: {
            de:'Kursabschluss',
            en:'Graduation'
        },
        count: {
            de:'4 Kurse | 50m 00s',
            en:'4 Courses | 50m 00s'
        },
        popup:{
            de:'Hier findest du alle Informationen zum Kursabschluss. Beispiel-Fragen bereiten dich perfekt auf den Abschlusstest vor, damit deinem Erfolg nichts mehr im Wege steht.',
            en:'Here you will find all the information you need to complete the course. Sample questions and a summary prepare you perfectly for the final test'
        }
    }
]

export const CssButton = withStyles({
    root: {
        background: 'rgb(82, 187, 171)',
        borderRadius: '3px',
        color: 'white',
        whiteSpace: 'nowrap',
        '&:hover': {
            background: 'rgb(82, 187, 171)',
        },
        '& .MuiFormControl-marginNormal': {
            margin: '0px'
        }
    },
    label: {
        textTransform: 'none',
    },
})(Button)

function CoursePage({t, i18n}) {

    const Transition = forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const [isVideo, setIsVideo] = useState(false)

    const [openSocialArea, setOpenSocialArea] = useState(false);

    const handleOpenSocialArea = () => {
        setOpenSocialArea(true);
    };
    const handleCloseSocialArea = () => {
        setOpenSocialArea(false);
    };

    const [openFooter, setOpenFooter] = useState(false);

    const handleOpenFooter = () => {
        setOpenFooter(true);
    };
    const handleCloseFooter = () => {
        setOpenFooter(false);
    };
    
    const [cookie, setCookie] = useCookies()

    if(cookie.password !== PASSWORD){
        return <Redirect to={`/login`} />
    }

    return (
        <div className={cl('course-page')}>
            {isVideo && <>
                <ModalVideo channel='vimeo' autoplay={true} isOpen={isVideo} videoId={t('VimeoID')}  onClose={() => setIsVideo(false)} />
            </>}
            {
                openSocialArea &&
                <div>
                    <Dialog
                        open={openSocialArea}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseSocialArea}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description-area">
                                {t('modalDeineSlackCommunity')}
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                </div>
            }
            <div className={cl('course-page__line__top')}/>
            <div className={cl('course-page__wrapper')}>
                <div className={cl('course-page__startsite')}>
                    <div className={cl('startsite_wrapper', 'container')}>
                        <img className={cl('startsite_image')} src={startsite} alt="startsite"/>
                        <p className={cl('startsite_title')}>{t('underheader')}</p>
                    </div>
                </div>
            </div>
            <main className={cl('main_conten_wrapper', 'container')}>
                {i18n.language === 'de' &&   <img className={cl('main_content_header_img')} src={startsite_header_de} alt=""/>}
                {i18n.language === 'en' &&   <img className={cl('main_content_header_img')} src={startsite_header_en} alt=""/>}
                <div className={cl('main_content')}>
                    <div className={cl('main_content_video__wrapper')}>
                        <p className={cl('main_content_video__title')}>{t('welcome')}</p>
                        <div className={cl('main_content_video')}>
                            <div style={{backgroundImage:`url(${willkommen})`}} className={cl('main_conten_wrapper__text', 'main_content_wrapper__text__right')}>
                                <p className={cl('main_conten_wrapper__text__title')}>
                                    {t('btnIntroductionVideo')}
                                </p>
                                <div>
                                    <CssButton onClick={() => {setIsVideo(true)}}>
                            <span className={cl('main_conten_wrapper__text__btn')}>
                               {t('btnThisWay')}
                            </span>
                                    </CssButton>
                                </div>
                            </div>
                        </div>
                        <div style={{height:"30px"}}/>
                        <div className={cl('main_content_video')}>
                            <div style={{backgroundImage:`url(${slack})`}} className={cl('main_conten_wrapper__text', 'main_content_wrapper__text__right')}>
                                <p className={cl('main_conten_wrapper__text__title')}>
                                    {t('btnOurSlackCommunity')}
                                </p>
                                <div>
                                    <CssButton onClick={handleOpenSocialArea}>
                            <span className={cl('main_conten_wrapper__text__btn')}>
                                 {t('btnGetInTouch')}
                            </span>
                                    </CssButton>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={cl('main_content_list')}>
                        <p className={cl('main_content_list__title')}>{t('deinOmr')}</p>
                        <div className={cl('main_content_list__grid')}>
                            {listCourse.map(el => {
                                return    <CourseCard lang={i18n.language} key={el.title} {...el}/>
                            })}
                        </div>
                    </div>
                </div>
                <div style={{backgroundImage: `url(${startsite_footer})`}} className={cl('main_content__footer')}>
                    <div className={cl('main_conten_wrapper__text')}>
                        <p className={cl('main_conten_wrapper__text__title')}>
                            {t('supportTeam')}
                        </p>
                        <div>
                            <div>
                                <CssButton onClick={handleOpenFooter}>
                            <span className={cl('main_conten_wrapper__text__btn')}>
                                    {t('btnPleaseContact')}
                            </span>
                                </CssButton>

                            </div>
                            {
                                openFooter &&
                                <div>
                                    <Dialog
                                        open={openFooter}
                                        TransitionComponent={Transition}
                                        keepMounted
                                        onClose={handleCloseFooter}
                                        aria-describedby="alert-dialog-slide-description"
                                    >
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-slide-description-footer">
                                                {t('modalPleaseContact')}
                                            </DialogContentText>
                                            <div className='wrapper_buttons_footer'>
                                                <a rel="noreferrer"  target='_blank' href='https://education.omr.com/collections/omr-academy?utm_source=omrprobecampus&utm_medium=referral'>
                                                    <CssButton> {t('btnBuyTicket')}</CssButton>
                                                </a>
                                                <a rel="noreferrer"  target='_blank' href={'mailto:education@omr.com'}>
                                                    <CssButton>{t('btnBookMeeting')}</CssButton>
                                                </a>
                                            </div>
                                        </DialogContent>
                                    </Dialog>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div style={{backgroundImage: `url(${startsite_footer})`}} className={cl('main_content__footer')}>
                    <div className={cl('main_conten_wrapper__text')}>
                        <p className={cl('main_conten_wrapper__text__title')}>
                            {t('coursesDetail')}
                        </p>
                        <div>
                            <div>
                                <a 
                                target="_blank"
                                href={t('coursebrochure')}>
                                    <CssButton>
                                        <span className={cl('main_conten_wrapper__text__btn')}>
                                            {t('btnCoursesDetail')}
                                        </span>
                                    </CssButton>
                                </a>
                                
                            </div>
                        </div>
                    </div>
                </div>
              
            </main>
        </div>
    );
}

export default withNamespaces()(CoursePage);
