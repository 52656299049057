import cl from "clsx";
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import {Dialog, DialogContent, DialogContentText, Slide} from "@material-ui/core";
import {forwardRef, useState} from "react";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function CourseCard({img, title, count, popup, lang}) {

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    return (
        <div>
            {popup &&
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {popup[lang]}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            }
            {
                popup &&

                <div style={{cursor:'pointer'}} onClick={handleOpen} className={cl('course_card')}>
                    <div className={cl('course_card_one')}/>
                    <div className={cl('course_card_two')}/>
                    <div style={{background: `url(${img[lang]})`}} className={cl('course_card_image')}/>
                    <div className={cl('course_card_content')}>
                        <p className={cl('course_card__title')}>{title[lang]}</p>
                        <p className={cl('course_card__subtitle')}>{lang === 'de' ? 'EINGESCHRIEBEN' : 'REGISTERED'}</p>
                        <p className={cl('course_card__count')}>{count[lang]}</p>
                    </div>
                    <div className={cl('course_card_footer')}>
                        <FilterNoneIcon fontSize='small'/>
                        <p className={cl('course_card_footer__text')}>{lang === 'de' ? 'Lernbereich' : 'Learning area'}</p>
                    </div>
                </div>
            }
            {
                !popup &&

               <div style={{cursor:'pointer'}}>
                   <a rel="noreferrer" target='_blank' href={lang === 'de' ? 'https://rise.articulate.com/share/T_UkOOK2dIcvhPa0vu8FjrPFwJ8r6LLo' : 'https://rise.articulate.com/share/eI3QEO3QxXfciHSlWP1NYq1-KmoYSYYY#'}>
                       <div className={cl('course_card')}>
                           <div className={cl('course_card_one')}/>
                           <div className={cl('course_card_two')}/>
                           <div style={{background: `url(${img[lang]})`}} className={cl('course_card_image')}/>
                           <div className={cl('course_card_content')}>
                               <p className={cl('course_card__title')}>{title[lang]}</p>
                               <p className={cl('course_card__subtitle')}>{lang === 'de' ? 'EINGESCHRIEBEN' : 'REGISTERED'}</p>
                               <p className={cl('course_card__count')}>{count[lang]}</p>
                           </div>
                           <div className={cl('course_card_footer')}>
                               <FilterNoneIcon fontSize='small'/>
                               <p className={cl('course_card_footer__text')}>{lang === 'de' ? 'Lernbereich' : 'Learning area'}</p>
                           </div>
                       </div>
                   </a>
               </div>
            }

        </div>

    );
}

export default CourseCard;
