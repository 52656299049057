import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from "./theme";
import { CookiesProvider } from 'react-cookie';
import {BrowserRouter as Router} from 'react-router-dom'
import './i18n';
ReactDOM.render(
  <React.StrictMode>
      <Router>
          <CssBaseline>
              <ThemeProvider theme={theme}>
                  <CookiesProvider>
                      <App />
                  </CookiesProvider>
              </ThemeProvider>
          </CssBaseline>
      </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
